<template>
  <v-expansion-panels mandatory>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ $t('contact') }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-text-field
          filled
          :label="$t('username')"
          v-model="value.displayName"
        />
        <v-text-field
          filled
          :label="$t('email')"
          v-model="value.email"
        />
        <v-text-field
          filled
          :label="$t('phone')"
          v-model="value.phone"
        />
      </v-expansion-panel-content>  
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

  export default {
    name: 'UserForm',
    props: {
      value: {
        type: Object, 
        default: () => {}
      }
    },
    data: () => ({
      building: false
    })
  }
</script>
